.nuka {
  height: 100% !important;
}

.arrow {
  color: white;
  background: rgba(0, 0, 0, 0.8);
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}
