.videos {
  margin-top: 4rem;
  margin-bottom: 4rem;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}

.video {
  position: relative;
  scroll-snap-align: center;
  margin-right: 1.25rem;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
