.header {
  position: relative;
  width: 100vw;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.heading {
  position: relative;
}

.title {
  font-size: 2.5rem;
  line-height: 3.25rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  color: white;
  background: black;
  word-break: break-word;
}

.highlight {
  color: deeppink;
}

@media (min-width: 40rem) {
  .header {
    min-height: 80vh;
  }

  .title {
    font-size: 4rem;
    line-height: 5.5rem;
  }
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}