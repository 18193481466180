.footer {
  padding: 5rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: contrast(1.1) saturate(4);
}

.text {
  background: black;
  color: white;
  padding: 0.25rem 0.5rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}