.project {
  width: 100%;
  min-height: 32rem;
  margin-bottom: 10rem;
  background: white;
  border: 0.5rem solid black;
  display: flex;
}

.image a {
  width: 100%;
  height: 100%;
}

.mainTitle {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 3rem;
  color: black;
  margin: 0;
}

.title {
  font-weight: normal;
}

.titleBold {
  font-weight: bold;
}

.links {
  margin-top: 0.75rem;
}

.markdown {
  font-size: 1rem;
  line-height: 1.3;
  text-align: justify;
}

.markdown p,
.markdown ul {
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.more {
  margin-top: 3rem;
  margin-bottom: 0;
}

.toggle {
  color: deeppink;
  border: 0;
  padding: 0;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}

.elmo .project {
  justify-content: space-between;
}

.elmo .image {
  flex-basis: 45%;
  flex-shrink: 0;
  order: 2;
}

.elmo .content {
  order: 1;
  flex-basis: auto;
  flex-shrink: 1;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.bigbird .project {
  flex-direction: column;
  padding-left: 7rem;
  padding-right: 7rem;
}

.bigbird .image {
  height: 26rem;
}

.bigbird .content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.bigbird .name {
  padding-top: 0.5rem;
}

.bigbird .more {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.viewport {
  height: 100%;
}

@media (max-width: 60rem) {
  .project {
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-width: 0.25rem;
  }

  .bigbird .project {
    padding-left: 0;
    padding-right: 0;
  }

  .project .image {
    order: 1;
    height: 22rem;
  }

  .project .content {
    order: 2;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .project .name {
    font-size: 1.5rem;
  }

  .project .toggle {
    font-size: 1rem;
  }

  .project .more {
    max-width: 100%;
  }
}