.container {
  width: 100%;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 60rem) {
  .container {
    max-width: 75vw;
  }
}