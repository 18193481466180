.about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.img {
  width: 20rem;
  border-radius: 50%;
}

.info {
  padding-top: 3rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item {
  margin-left: 1rem;
  margin-right: 1rem;
}

.item:first-child {
  color: deeppink;
  font-weight: bold;
}

@media (min-width: 40rem) {
  .about {
    padding-top: 0;
    flex-direction: row;
  }

  .info {
    padding-top: 0;
  }
}